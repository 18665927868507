$breakpoint-m:			30em; //  480px
$breakpoint-ml:         35em;
$breakpoint-l:			40em; //  640px
$breakpoint-xl:			55em; //  880px
$breakpoint-xxl:		60em; //  960px
$breakpoint-xxxl:		80em; // 1280px
$breakpoint-xxxxl:		90em; // 1280px

// Fonts
$font-fallback-stack: 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-heading: 'Roboto', sans-serif, Helvetica, Arial, sans-serif;
$font-default: 'Roboto', sans-serif, Helvetica, Arial, sans-serif;

// Colors
$color-gray-lighter:    #e8e8e8;
$color-gray-light:      #cccccc;
$color-gray:            #4e4e4e; //#424242;
$color-gray-dark:       #333333;
$color-gray-darker:     #2b2b2b;
$color-black:           #000000;
$color-white:           #FFFFFF;
$color-white-off:       #FFFFFF;

// Colors
$color-primary-light:   #D8D7F9;
$color-primary:         #4D478A;
$color-primary-dark:    #272263;

// Specials
$border-radius: 7px;
$border-radius-round: 6em;
$box-shadow: 0 0 0.5em 0 rgba(0, 0, 0, 0.4);